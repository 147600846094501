@tailwind base;
@tailwind components;
@tailwind utilities;

.circle-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transform: rotate(-90deg);
}
/*
* {
  outline: red solid 1px;
}*/

.skeleton > * > * {
  background-image: linear-gradient(
    to right,
    rgb(19 7 7 / 0.05),
    rgb(19 7 7 / 0.1)
  );
  color: transparent;
  animation: skeleton-loading 2s linear infinite;
  border-radius: 1rem;
  margin: 0 0 5px 0;
  pointer-events: none;
  user-select: none;
}
.skeleton > img {
  background-image: linear-gradient(
    to right,
    rgb(19 7 7 / 0.05),
    rgb(19 7 7 / 0.1)
  );
  color: transparent;
  animation: skeleton-loading 2s linear infinite;
  pointer-events: none;
  user-select: none;
}

.skeleton-self {
  background-image: linear-gradient(
    to right,
    rgb(19 7 7 / 0.05),
    rgb(19 7 7 / 0.1)
  );
  color: transparent;
  animation: skeleton-loading 2s linear infinite;
  pointer-events: none;
  user-select: none;
}
@keyframes skeleton-loading {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-position-x: 0;
  }
}
